﻿import {
    assert,
    ComplexProp,
    ComplexPropIter,
    Core,
    gfu2,
    LCMDContextCardStatus,
    ParticleGetter,
} from "../../core/lcmd2core";
import { DataOperation, DataOperationTarget, DataOperationType } from "../DataModel";
import { AttachedChecklist, AttachedChecklistId } from "@/core/services/Checklist.service";
import { getStatusForCards } from "@/components/common/ProcessTooltip/helper";
import { ProcessStatuses } from "@/model/GlobalHelper";

export class ProcessGetter {
    private readonly _core: Core;
    private _id: number = Number.MIN_SAFE_INTEGER;
    private _r: any = {};

    public readonly statusIdentifier = "_status";
    public readonly endDateIdentifier = "_x2";

    constructor(core: Core, id?: number) {
        this._core = core;
        this.reset(id);
    }

    public get core(): Core {
        return this._core;
    }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this.reset(id);
    }

    public reset(id: number): ProcessGetter {
        const _r = undefined !== id && this._core._model.tasks[id];
        this._id = _r ? id : Number.MIN_SAFE_INTEGER;
        this._r = _r || {};
        return this;
    }

    public clearVirtualChildrenIfExists() {
        if (this.hasVirtualChildren()) {
            this._r._c = []; // clear generated
        }
    }

    public hasVirtualChildren() {
        const _c = this._r._c;
        if (Array.isArray(_c) && _c.length > 0) {
            const m = this._core._model;
            const ret = null === m.VALUE<number | null>(m.tasks[_c[0]]?.p, null); // first child with no parent => virtual child
            if ("development" === process.env.NODE_ENV) {
                if (ret) {
                    assert(
                        _c.reduce((ret, tid) => ret && null === m.VALUE<number | null>(m.tasks[tid]?.p, null), true),
                    ); // make sure all children are virtual
                } else {
                    assert(
                        _c.reduce((ret, tid) => ret && null !== m.VALUE<number | null>(m.tasks[tid]?.p, null), true),
                    ); // make sure no child is virtual
                }
            }
            return ret;
        } else {
            return false;
        }
    }

    public appendVirtualChild(id: number) {
        const m = this._core._model;
        const tz = this._r;
        assert(m.tasks[id]?.__ < 0 && Array.isArray(tz?._c)); // virtual child!
        tz._c.push(id);
        assert(
            "development" !== process.env.NODE_ENV ||
                tz._c.reduce((ret, tid) => ret && null === m.VALUE<number | null>(m.tasks[tz._c[tid]]?.p, null), true),
        );
    }

    isArea(): boolean {
        return Boolean(this.getChildren());
    }

    public getChildren(): number[] | undefined {
        return this._r._c;
    }

    public _(name: string | ComplexProp, v0?: number) {
        const _name = "string" === typeof name ? name : name._value;
        return this._core._model.OP_I(this._r[_name], v0);
    }

    public history(name: string | ComplexProp): number[] {
        const _name = "string" === typeof name ? name : name._value;
        return this._core._model.OPS_I(this._r[_name]);
    }

    public isDeleted() {
        return this._r ? this._core._model.isTaskDel(this._r) : false;
    }

    public isVisible() {
        return this.aux("_stripe1") >= 0;
    }

    public isMilestone() {
        return Boolean(this.value<number>("days")) === false;
    }

    public hasMilestoneState() {
        return this.isMilestone() && this.value<number>("milestone-state", 0) >= 0;
    }

    public getMilestoneState() {
        if (!this.isMilestone()) {
            // should throw error ? or maybe just print warning ?
            return null;
        }
        return this.value<number>("milestone-state", 0);
    }

    public get state(): ProcessStatuses {
        const processCards = this.core.gatherActivitiesForTask(this);
        const { s: status } = getStatusForCards(
            this.core.getViewConst().today,
            this.aux(this.endDateIdentifier),
            processCards?.cards,
        );
        return status;
    }

    public value<T>(name: string | ComplexProp, v0?: T | null): T {
        const m = this._core._model;
        const _name = "string" === typeof name ? name : name._value;
        assert("development" !== process.env.NODE_ENV || !_name.startsWith("_"));
        return m.VALUE<T>(this._r[_name], v0);
    }

    public aux<T>(name: string, v0?: T): T {
        assert("development" !== process.env.NODE_ENV || name.startsWith("_"));
        return gfu2(this._r[name], v0);
    }

    public setAux<T>(name: string, v: T) {
        assert("development" !== process.env.NODE_ENV || name.startsWith("_"));
        this._r[name] = v;
    }

    public unit(name: string | ComplexProp, v0: number): number {
        const m = this._core._model;
        const _name = "string" === typeof name ? name : name._value;
        assert("development" !== process.env.NODE_ENV || !_name.startsWith("_"));
        return m.UNIT(this._r[_name], v0);
    }

    public getAllProps(): ComplexPropIter {
        return new ComplexPropIter(Object.getOwnPropertyNames(this._r));
    }

    public getComments() {
        const allPropertiesIterator = this.getAllProps();
        const complexProp = new ComplexProp();
        const commentsAttachedToProcess = new Map();

        for (allPropertiesIterator.first(complexProp); allPropertiesIterator.next(complexProp); ) {
            const isComment = complexProp.isCOMMENTID();
            if (!isComment) {
                continue;
            }

            commentsAttachedToProcess.set(complexProp._value, this.value(complexProp));
        }

        return commentsAttachedToProcess;
    }

    public get isVirtual() {
        return this._r ? this._r.__ < 0 : false;
    }

    public setCanvasDirty() {
        if (this._r) {
            this._r._canvasDirty = true;
        }
    }

    public clone(): ProcessGetter {
        const ret = new ProcessGetter(this.core, this.id);
        return ret;
    }

    public getOPS(): DataOperation[] {
        const pr = new ComplexProp();
        const it = this.getAllProps();
        let processHist: number[] = [];
        if (this.id >= 0) {
            // valid process
            for (it.first(pr); it.next(pr); ) {
                let helper;
                if ((helper = pr.isNAME())) {
                    processHist = processHist.concat(this.history(helper));
                } else if ((helper = pr.isTRADEREF())) {
                    processHist = processHist.concat(this.history(pr));
                } else if ((helper = pr.isCOMMENTID())) {
                    const commentHist = this.history(pr);
                    // will break if order or syntax of comment operations is changed
                    assert(commentHist.length === 1);
                    processHist = processHist.concat([commentHist[0] - 1, commentHist[0]]);
                } else if ((helper = pr.isACTIONITEM())) {
                    const aiHist = this.history(pr);
                    // check, if previousOperation has target = comment (then it's a new created actionitem)
                    const previousOperationId = aiHist.at(-1) - 1;
                    const previousOperation = new ParticleGetter(this.core, previousOperationId);
                    if (
                        previousOperation.operation === DataOperationType.CREATE &&
                        previousOperation.target === DataOperationTarget.COMMENT &&
                        previousOperation.group &&
                        helper.id === previousOperation.id
                    ) {
                        aiHist.splice(-1, 0, previousOperationId);
                    }
                    processHist = processHist.concat(aiHist);
                } else if ((helper = pr.isCARDPROP())) {
                    processHist = processHist.concat(this.history(pr));
                }
            }
        }

        processHist.sort();
        const opsList = this.core._model.OPS(processHist);
        assert(opsList.length === processHist.length);

        const indexedHistList: DataOperation[] = [];
        for (let i = 0; i < processHist.length; i++) {
            const ts = processHist[i];
            const op = { ...opsList[i] };

            //LCM2-4145: modified the original group prop, so the process history can separate the multi-changes
            if (processHist[i + 1] !== ts + 1 && Boolean(op.group) === true) {
                op.group = false;
            }

            indexedHistList[ts] = op;
        }

        return indexedHistList;
    }

    public getValueBeforeTimestamp(timestamp: number, propertyName: string | ComplexProp): ParticleGetter | undefined {
        const propHistories = this.history(propertyName);
        for (let i = 0; i < propHistories.length; i++) {
            if (timestamp > propHistories[i]) {
                return new ParticleGetter(this.core, propHistories[i]);
            }
        }
    }

    //@nikhil checklist_api
    public getChecklists(): Map<AttachedChecklistId, AttachedChecklist> | null {
        const _gT = new ParticleGetter(this.core);
        const it = this.getAllProps();
        const property = new ComplexProp();
        const checklistAttachedToProcess = new Map();
        let checkpointsLength: number = 0;
        for (it.first(property); it.next(property); ) {
            let helper;
            if ((helper = property.isCHECKLIST())) {
                if (helper.prop) {
                    // values for item
                    if (checklistAttachedToProcess.has(helper.id) && helper.prop !== "createdAt") {
                        let propValue = this.value<any>(property, undefined);
                        const existingProps = checklistAttachedToProcess.get(helper.id);
                        switch (helper.prop) {
                            case "checklist":
                                propValue = JSON.parse(propValue);
                                checkpointsLength = propValue.checkpoints.length;
                                checklistAttachedToProcess.set(helper.id, {
                                    ...existingProps,
                                    ...propValue,
                                });
                                break;
                            case "data":
                                propValue = this.core.decodeAttachedChecklistStates(propValue, checkpointsLength);
                                checklistAttachedToProcess.set(helper.id, {
                                    ...existingProps,
                                    [helper.prop]: propValue,
                                });
                                break;
                        }
                    }
                } else {
                    const historyOpIds = this.history(property);
                    assert(historyOpIds.length > 0);
                    const v = historyOpIds.length > 1 ? _gT.reset(historyOpIds[0]).value() : undefined; // initial value is always CREATE and is always the type of the action item
                    if (historyOpIds.length > 1 && !v) {
                        // deleted
                        // do nothing...
                    } else {
                        checklistAttachedToProcess.set(helper.id, {
                            id: helper.id,
                            createdAt: _gT.createdAt,
                            pid: this.id,
                        });
                    }
                }
            }
        }
        return checklistAttachedToProcess.size > 0 ? checklistAttachedToProcess : null;
    }
}
