import "./prepare";
import { DialogType, initializeIcons } from "@fluentui/react";
import "setimmediate";
import en_US from "../locales/en-US.json";
import de_DE from "../locales/de-DE.json";
import fr_FR from "../locales/fr-FR.json";
import { assert, checkForUpdates, initFramework, intl } from "lcmd2framework";
import { Dashboard } from "../components/dashboard";
import { FilterButton } from "../components/common/FilterButton/FilterButton";
import { FilterDialog } from "../components/Filter";
import { ResourceChart } from "../components/ResourceChart";
import { ProjectPropertiesDialog } from "../components/project-properties-dialog";
import { ProjectAccountDialog } from "../components/project-account-dialog";
import { ProjectFindDialog } from "../components/project-find-dialog";
import { Settings } from "../components/Settings";
import { PreviewButton, Whiteboard, WhiteboardPropertiesDialog } from "../components/whiteboard/Whiteboard";
import { PdfExportDialog } from "../components/pdf-export-dialog";
import { ToDo } from "../components/ToDo";
import { GroundLayoutModal } from "../components/ground-layout-modal";
import { MilestonesChart } from "../components/MilestonesChart";
import { GeneralError } from "../components/common/ErrorNotifications/GeneralError";
import { TaktzoneCloneDialog } from "../components/taktzone-clone-dialog";
import { ChangesPanel } from "../components/ChangesPanel/ChangesPanel";
import { CardDialog } from "../components/CardDialog/CardDialog";
import { ShareDialog } from "../components/ShareDialog";
import { FilesOverlay } from "../components/FilesOverlay";
import { CollaborationDialog } from "../components/CollaborationDialog";
import { TradesSelectionDialog } from "../components/Filter/dialogs/FilterDialog";
import { ProcessViewDialog } from "../components/ProcessViewDialog";
import { FileUploadPopup } from "@/components/FileUploadPopup";
import { DeleteTradeDialog } from "../components/trade-delete-dialogs/TradeDelete.dialog";
import { CanvasProjectHost } from "../components/CanvasProjectHost";
import { CanvasWhiteboardHost } from "../components/CanvasWhiteboardHost";
import { getLCMDContext, LCMDContextType } from "./LCMContext";
import { Legend } from "./components/Legend";
import { HeaderMetaComponent } from "./components/HeaderMetaComponent";
import { SidebarHost } from "./components/SidebarHost";
import { ConnectionErrorAlertBox } from "./components/ConnectionErrorAlertBox";
import { DownloadDialog } from "./components/DownloadDialog";
import { OnErrorProps } from "../legacy/ScreenOfDeath";
import { ReasonDisturbance } from "../components/common/ErrorNotifications/ReasonDisturbance/ReasonDisturbance";
import userflow from "userflow.js";
import "../index.css";
import posthog from "posthog-js";

// Ensure flags are loaded before usage.
// You'll only need to call this on the code for when the first time a user visits.
posthog.onFeatureFlags(function () {
    // feature flags should be available at this point
    if (posthog.isFeatureEnabled("ff-userflow")) {
        //Higher than the highest element in the app -> currently components/ui/popover.tsx z-[100000100]
        //But do not put it too high either. Otherwise userflow elements themselves become unclickable.
        console.log("userflow active!");
        userflow.setBaseZIndex(999999999);
    }
});

initializeIcons();
/* TODO: Init fluentui theme...
loadTheme({
    palette: {
        themePrimary: '#FF0000',
    }
});
*/

const onError = ({ forcedUpdate, buildInfo, projectId }: OnErrorProps) => {
    return (
        <GeneralError
            onReload={() => location.reload()}
            isAlert={true}
            isBlocking={true}
            isOpen={true}
            forcedUpdate={forcedUpdate}
            buildInfo={[buildInfo?.build || null, buildInfo?.hash || null].join("-")}
            checkForUpdates={checkForUpdates}
            projectId={projectId}
        />
    );
};

function onRejectedOps(LCMD: LCMDContextType) {
    LCMD.showDialog("fw.alert", {
        dialogContentProps: {
            type: DialogType.normal,
            title: intl.get("previewModeDialog.title"),
            subText: intl.get("previewModeDialog.subText"),
        },
        onOK: () => {
            LCMD.showDialog("fw.alert", null);
        },
    });
}

function onKeyDown(LCMD: LCMDContextType, event: KeyboardEvent) {
    switch (event.key) {
        case "f":
            if (event.ctrlKey || event.metaKey) {
                // LCMD.showDialog("dialog.find", {});

                // todo: Implement enum/type for CustomEvent Names
                const searchEvent = new CustomEvent("spotlight-search");
                document.dispatchEvent(searchEvent);
                event.preventDefault();
            }
            return true;
        case "l":
            if (event.ctrlKey && event.altKey && event.metaKey) {
                // see https://lcmexecute.atlassian.net/browse/LCM2-2288?focusedCommentId=12674
                LCMD.worker.postMessage([
                    "export",
                    {
                        target: "log",
                        cb: LCMD.worker.registerCallback((data) => {
                            LCMD.showDialog("dialog.lcmd.download", data);
                        }),
                    },
                ]);
                event.preventDefault();
            }
            return true;
        case "C":
            if ("development" === process.env.NODE_ENV && event.ctrlKey && event.shiftKey) {
                // for debug :-)
                assert(false, "crash test"); //  crash
            }
            return true;
        default:
            return false;
    }
}

initFramework({
    _canary: import.meta.env.VITE_IS_CANARY === "TRUE",
    getParticleContext: getLCMDContext,
    DAILYBOARD_URL: (opt: { isReleaseCandidate: boolean; isCanary: boolean }) => {
        if (location.origin.includes("localhost") || location.origin.includes("127.0.0.1")) {
            return `http://${location.hostname}:3001?`;
        }

        return `${location.origin}/dailyboard/index_${__DAILYBOARD_HASH__}.html?`;
    },
    onError,
    onRejectedOps,
    onKeyDown,
    locales: {
        "en-US": en_US,
        "de-DE": de_DE,
        "fr-FR": fr_FR,
    },
    dashboard: Dashboard,
    //dashboard: WelcomeScreen,    for quick testing of the WelcomeScreen
    filterDialog: FilterDialog,
    filterButton: FilterButton,
    resourceChart: ResourceChart,
    milestonesChart: MilestonesChart,
    headerMetaComponent: HeaderMetaComponent,
    projects: {
        cmd: [],
    },
    dialogs: {
        "dialog.project.properties": async () => {
            return <ProjectPropertiesDialog />;
        },
        "dialog.taktzone.details": async (params) => {
            return <GroundLayoutModal dialogId="dialog.taktzone.details" {...params} />;
        },
        "dialog.export.pdf": async (params) => {
            return <PdfExportDialog {...params} />;
        },
        "dialog.taktzone.clone": async (params) => {
            return <TaktzoneCloneDialog {...params} />;
        },
        "dialog.whiteboard.properties": async (params) => {
            return <WhiteboardPropertiesDialog {...params} />;
        },
        "dialog.project.collaborators": async (params) => {
            return <CollaborationDialog {...params} dialogId="dialog.project.collaborators" />;
        },
        "dialog.lcmd.download": async (params) => {
            // see https://lcmexecute.atlassian.net/browse/LCM2-2288?focusedCommentId=12674
            return <DownloadDialog {...params} />;
        },
        "dialog.project.upload": async (params) => {
            return <FileUploadPopup {...params} />;
        },
        "dialog.project.clone": async (params) => {
            return <FileUploadPopup {...params} />;
        },
        "dialog.project.account": async () => {
            return <ProjectAccountDialog />;
        },
        "dialog.trade.delete": async (params) => {
            return <DeleteTradeDialog {...params} />;
        },
        "dialog.find": async () => {
            return <ProjectFindDialog />;
        },
    },
    Todo: ToDo,
    Settings: Settings,
    //PersonalSettings: PersonalSettings,
    Sidebar: SidebarHost,
    whiteboard: Whiteboard,
    previewButton: PreviewButton,
    ChangesPanel: ChangesPanel,
    Legend: Legend,
    CardDialog: CardDialog,
    TradesSelectionDialog: TradesSelectionDialog,
    ReasonDisturbance: ReasonDisturbance,
    ShareDialog: ShareDialog,
    FilesOverlay: FilesOverlay,
    ProcessViewDialog: ProcessViewDialog,
    ConnectionErrorAlertBox: ConnectionErrorAlertBox,
    //DISABLED CollaborationFacepile: CollaborationFacepile
    CanvasProjectHost: CanvasProjectHost,
    CanvasWhiteboardHost: CanvasWhiteboardHost,
});
